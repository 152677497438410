<template>
  <div class="input-province">
    <b-form-group :label-for="inputName" :invalid-feedback="veeErrors.first(inputName)">
      <template slot="label">{{ label }}<span v-if="required" class="text-danger"> *</span></template>
      <b-form-select
        :name="inputName"
        v-model="province"
        v-validate="{ required: required }"
        :state="validateState()"
        :data-vv-as="errorMessage"
        :aria-describedby="`${inputName}-feedback`"
        :options="provinceOptions"
      >
        <template #first>
          <b-form-select-option :value="null">{{ defaultOptionText }}</b-form-select-option>
        </template>
      </b-form-select>
    </b-form-group>
  </div>
</template>

<script>
import provinceStateViewService from '@/lib/province-state-view-service';
export default {
  name: 'ProvinceDropdownInput',
  props: {
    name: {
      type: String,
      required: true,
      default: 'province'
    },
    country: {
      type: String,
      required: true
    },
    value: {
      type: String,
      default: null
    },
    required: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    },
    state: {
      type: Boolean,
      default: null
    },
    invalidFeedback: {
      type: String,
      default: ''
    },
    label: {
      type: String,
      default: ''
    }
  },

  data() {
    return {
      provinceOptions: []
    };
  },

  created() {
    this.$validator = this.parentValidator;
    const model = provinceStateViewService.getDefaultView(this.country);

    if (model) {
      this.provinceOptions = model.dropdownOptions.map((option) => {
        return {
          value: option.value,
          text: option.text
        };
      });
    }
  },
  watch: {
    country() {
      const model = provinceStateViewService.getDefaultView(this.country);

      if (model) {
        this.provinceOptions = model.dropdownOptions;
      }
    }
  },
  computed: {
    inputName() {
      return `input-${this.name}`;
    },
    errorMessage() {
      if (this.label) {
        return this.label.toLowerCase();
      }

      return '';
    },
    province: {
      get() {
        return this.value;
      },
      set(province) {
        this.$emit('input', province);
      }
    },
    defaultOptionText() {
      return this.country === 'CA' ? '-- Please select a Province --' : '-- Please select a State --';
    }
  },
  inject: ['parentValidator'],
  methods: {
    validateState() {
      if (
        this.veeFields[this.inputName] &&
        (this.veeFields[this.inputName].dirty || this.veeFields[this.inputName].validated)
      ) {
        return !this.veeErrors.has(this.inputName);
      }

      return null;
    },
    isValid(silent = false) {
      this.$validator.validate(null, null, { silent: silent });

      return this.veeFields[this.inputName]['invalid'] === false;
    }
  }
};
</script>

<style>
.input-province .form-group {
  margin-bottom: 1rem;
}
.input-province .form-group label {
  display: block;
}
.input-province .form-group select {
  background-color: #ffffff;
}
.input-province .form-control {
  display: block;
  width: 100%;
  height: calc(1.5em + 1.2rem + 2px);
  padding: 0.6rem calc(1.5em + 1.2rem) 0.6rem 1rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0.5rem;
  background: #fff
    url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='5' viewBox='0 0 4 5'%3e%3cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e")
    right 1rem center/8px 10px no-repeat;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  appearance: none;
}
.input-province .form-control.is-invalid {
  border-color: #dc3545;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' fill='none' stroke='%23dc3545' viewBox='0 0 12 12'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23dc3545' stroke='none'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right calc(0.375em + 0.3rem) center;
  background-size: calc(0.75em + 0.6rem) calc(0.75em + 0.6rem);
}
.input-province .form-control.is-valid {
  border-color: #56b65e;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath fill='%2356b65e' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right calc(0.375em + 0.3rem) center;
  background-size: calc(0.75em + 0.6rem) calc(0.75em + 0.6rem);
}
.input-province select[disabled='disabled'] {
  background-color: #eeeeee;
}
</style>
